
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class GraphDateLegend extends Vue {
    @Prop({
        required: false,
        type: Array,
    })
    labels!: [];

    @Prop({
        required: false,
        type: Date,
    })
    date!: Date;

    @Prop({
        type: Boolean,
        default: false,
    })
    public skeleton!: boolean;

    get getDaysInMonth() {
        const date = this.date || new Date();
        const numberOfDays = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
        let cssClass: string = '';
        switch (numberOfDays) {
            case 31:
                cssClass = 'days-31';
                break;
            case 28:
                cssClass = 'days-28';
                break;
            case 29:
                cssClass = 'days-29';
                break;
            default:
                cssClass = 'days-30';
                break;
        }
        return cssClass;
    }
}
