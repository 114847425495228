import { render, staticRenderFns } from "./demand-line.vue?vue&type=template&id=55f79c4c&scoped=true&"
import script from "./demand-line.vue?vue&type=script&lang=ts&"
export * from "./demand-line.vue?vue&type=script&lang=ts&"
import style0 from "./demand-line.vue?vue&type=style&index=0&id=55f79c4c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55f79c4c",
  null
  
)

export default component.exports