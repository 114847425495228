import { RANGES } from './_demand.contants';

export function getDemandRange(demand: number) {
    const validRanges = RANGES.filter(range => {
        const isOverRange = demand >= range.max;
        const isInRange = demand >= range.min && demand <= range.max;

        return isOverRange || isInRange;
    });

    return validRanges.pop();
}

export function getDemandLevel(demand: number): number {
    const range = getDemandRange(demand);

    if (range) {
        return RANGES.indexOf(range) + 1;
    }

    return 0;
}
