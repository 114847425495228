
import { Component, Vue, Prop } from 'vue-property-decorator';
import OccupancyLine from './occupancy-line.vue';

type OccupancyType = 'vertical' | 'horizontal' | 'expanded';

/** Generic component for all occupancy item graphs.
 * If new visualisation of occupancy is needed, add another type to OccupancyType and template for it. */
@Component({
    components: { OccupancyLine },
})
export default class Occupancy extends Vue {
    @Prop({
        type: String,
        default: 'vertical',
    })
    type!: OccupancyType;

    @Prop({
        type: Number,
        default: 0,
    })
    value!: number;

    @Prop({
        type: Boolean,
        default: false,
    })
    titleHidden!: boolean;

    // To replace tiny-tooltip with regular browser title
    @Prop({
        type: Boolean,
        default: false,
    })
    browserTitle!: boolean;

    @Prop({
        type: String,
        default: null,
    })
    customTitle!: string;

    get parsedOccupancyValue() {
        if (!this.value || this.value < 0) {
            return 0;
        }

        return this.value;
    }
}
