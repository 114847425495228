
import {
    Component, Vue, Prop,
} from 'vue-property-decorator';
import type Day from '@/modules/common/types/day.type';
import RatesDayScanBtn from '@/modules/rates/components/rates-day-scan-btn.vue';

@Component({
    components: {
        RatesDayScanBtn,
    },
})
export default class RatesGraphTooltip extends Vue {
    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;

    handleDotsClick(e: Event) {
        e.stopPropagation();
    }

    handleTooltipClick() {
        this.$emit('click', this.day);
    }
}
