
import { Component, Vue, Prop } from 'vue-property-decorator';

/** It is part of occupancy/index.vue comonent. Use index.vue with type = vertical | horizontal if you need only demand line */
@Component
export default class Occupancy extends Vue {
    @Prop({
        type: Number,
        default: 0,
    })
    valuePercent!: number;

    @Prop({
        type: Boolean,
        default: false,
    })
    isHorizontal!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    titleHidden!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    browserTitle!: boolean;

    @Prop({
        type: String,
    })
    customTitle?: string;

    get occupancy() {
        return this.valuePercent;
    }

    get value() {
        const elHeight = 40;
        return elHeight - this.valuePercent / 100 * elHeight;
    }

    get top() {
        return this.isHorizontal ? 0 : this.value;
    }

    get right() {
        return this.isHorizontal ? this.value : 0;
    }

    get parsedTitle() {
        if (this.titleHidden) {
            return '';
        }

        return this.customTitle || `${this.$tc('titles.otb')} ${this.valuePercent}%`;
    }
}
