var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"hotels-legend",style:({ '--items-count': _vm.hotels.length })},_vm._l((_vm.hotels),function(hotel){return _c('div',{key:hotel.hotelId,class:{
            'hotels-legend__item': true,
            'hotels-legend__item--disabled': hotel.disabled,
            'hotels-legend__item--main': hotel.main,
            'hotels-legend__item--persistent': hotel.persistent,
        },on:{"click":function($event){return _vm.toggleHotel(hotel.hotelId);}}},[_c('div',{class:{
                [hotel.indicatorClass]: true,
                [hotel.indicatorClass + '--hidden']: hotel.disabled,
            },style:({ '--indicator-color': hotel.color })}),_c('span',{staticClass:"hotels-legend__name",attrs:{"data-autotests-id":`graph-label-hotel:${hotel.hotelId}`},domProps:{"textContent":_vm._s(hotel.name)}})])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }