
import { Vue, Component, Prop } from 'vue-property-decorator';
import Demand from '../demand/index.vue';
import Occupancy from '../occupancy/index.vue';
import GraphDateLegend from '../graph-date-legend.vue';

@Component({
    components: { Demand, Occupancy, GraphDateLegend },
})
export default class OccupancyDemandGraph extends Vue {
    @Prop({
        required: false,
        type: Array,
    })
    values!: { demand?: number; occupancy?: number }[];

    @Prop({
        required: false,
        type: Array,
    })
    labels!: number[];

    @Prop({
        required: false,
        type: String,
    })
    day!: string;

    @Prop({
        required: false,
        type: Date,
    })
    date!: Date;

    get isAnyDemand() {
        return !!this.values.find(v => v?.demand);
    }

    get isAnyOccupancy() {
        return !!this.values.find(v => v?.occupancy);
    }
}
